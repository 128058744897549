<template>
  <div id="app">
    <i class="fa-solid fa-cog fa-10x fa-spin cog"></i>
    <HelloWorld code="404" msg="This page is not available"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.cog {
  color: #2c3e50;
}
</style>
