<template>
  <div class="hello">
    <h1><span class="code">{{code}}</span>{{ msg }}</h1>
    <p>
      Please move to
      <a href="http://crm2b.org">CRM2B ORG production</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    code: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.code {
  color: #506686;
  margin-right: 10px;
}
a {
  color: #3660c7;
  text-decoration: none;
}
</style>
